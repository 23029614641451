import { Helmet } from "react-helmet";
import moment from "moment";
import { useEffect, useState } from "react";
import Layout from "Reusable/Tutor/Layout";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Loading from "Reusable/Common/LoadingComponents/loader/loader";
import EventModal from "./Event/event";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Styles from "./Styles.module.scss";
import { Services } from "Service/service";

import { CalenderDataGenerator } from "./eventGenerator";
const localizer = momentLocalizer(moment);

interface Event {
    start: Date;
    end: Date;
    title: string;
    TimeSlotID: string | number;
}

const Availability = () => {
    const [selectedDates, setSelectedDates] = useState<Event[]>([]);
    const [Modal, setModal] = useState(false);
    const [details, setDetails] = useState<Event>();
    const [disabledDates, setDisabledDates] = useState([]);
    const [edit, setEdit] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const GetTutorAvailability = async () => {
        if (!loading) {
            setLoading(true);
        }
        try {
            const TutorAvailability = await Services.getTutorAvailability(
                "GET",
                null,
                token
            );
            if (TutorAvailability.Status === 1) {
                setLoading(false);
                /*    let data = CalenderDataGenerator(
                    TutorAvailability.TutorAvailability
                );
                let disable = TutorAvailability?.TutorAvailability?.filter(
                    (item: any) => item.OnLeave == 1
                ).map((item: any) =>
                    moment(item.AvailableDate).format("YYYY-MM-DD")
                );
                setDisabledDates(disable);
                setSelectedDates(data); */
                let data = CalenderDataGenerator(
                    TutorAvailability.TutorAvailability.filter(
                        (item: any) => item?.OnLeave !== 1
                    )
                );
                /*   let disable = TutorAvailability?.TutorAvailability?.filter(
                    (item: any) => item.OnLeave == 1
                ).map((item: any) =>
                    moment(item.AvailableDate).format("YYYY-MM-DD")
                );
                setDisabledDates(disable); */
                setSelectedDates(data);
            } else {
                Error(TutorAvailability?.Message);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Error("Something went wrong please try again");
        }
    };
    useEffect(() => {
        GetTutorAvailability();
    }, []);
    const isDateDisabled = (date: any) => {
        return disabledDates.some((d) => moment(date).isSame(d, "day"));
    };
    const isAnyDateDisabled = (slots: any) => {
        return slots.some((date: any) =>
            moment(date).isBefore(moment(), "day")
        );
    };
    const handleSelect = ({ slots }: { slots: any }) => {
        if (isAnyDateDisabled(slots)) {
            return;
        }
        let isDisabled = slots.some((date: any) => isDateDisabled(date));
        if (isDisabled) {
            return;
        }
        setDetails(slots);
        setModal(true);
        // setSelectedDates([...selectedDates, newEvent]);
    };
    const onSelectEvent = (event: any) => {
        if (moment(event.start).isBefore(moment(), "day")) {
            return;
        }
        const isDisabled = disabledDates.some((d) =>
            moment(event.start).isSame(d, "day")
        );
        if (isDisabled) {
            return;
        }
        setEdit({
            TimeSlotID: event.TimeSlotID,
            StartTime: moment(event.start).format("HH:mm:ss"),
            EndTime: moment(event.end).format("HH:mm:ss"),
            Date: moment(event.start).format("YYYY-MM-DD"),
        });
        setModal(true);
    };
    const dayPropGetter = (date: any) => {
        const isDisabled = disabledDates.some((d) =>
            moment(date).isSame(d, "day")
        );
        if (moment(date).isBefore(moment(), "day") || isDisabled) {
            return {
                style: {
                    backgroundColor: "#e6e6e6", // Set your desired color for disabled dates
                },
            };
        }

        return {};
    };

    return (
        <Layout Active={"Tutor Availability"}>
            {loading && <Loading />}
            <EventModal
                getDetails={details}
                modal={Modal}
                setModal={setModal}
                setSelectedDates={setSelectedDates}
                GetTutorAvailability={GetTutorAvailability}
                edit={edit}
                setedit={setEdit}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Earnings</title>
            </Helmet>
            <div className={Styles["Container"]}>
                <div className={Styles["Tutor"]}>
                    <h3>Tutor Availability</h3>
                    <div className={Styles["calender"]}>
                        <Calendar
                            localizer={localizer}
                            events={[...selectedDates]}
                            popup
                            selectable
                            onSelectSlot={handleSelect}
                            onSelectEvent={onSelectEvent}
                            views={["month", "week", "day"]}
                            defaultView="month"
                            defaultDate={moment().toDate()}
                            dayPropGetter={dayPropGetter}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Availability;
