import { useState, useEffect } from "react";
import TakenClasses from "./Taken.module.scss";
import Moment from "react-moment";
import { IoVideocam } from "react-icons/io5";
import Rating from "./Rating/rating";
import ReactPaginate from "react-paginate";
import { FallingLines } from "react-loader-spinner";
import moment from "moment";
import { Error, warningSwal } from "Reusable/utils/Toast";
import { getCompletedClasses } from "Service/parent";
import Loader from "Reusable/Common/LoadingComponents/fallingLines/fallingLines";
const Taken: React.FC<any> = ({ StudentId }) => {
    const [modal, setModal] = useState(false);
    const [page, setPage] = useState(0);
    const [details, setDetails] = useState();
    const [allClasses, setAllClasses] = useState<any>([]);
    // const [forcepage, setforcepage] = useState(0);

    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);

    const [circleLoad, setCircleLoad] = useState(false);
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const getData = async (page: number) => {
        setCircleLoad(true);
        try {
            const getClasses = await getCompletedClasses(
                "POST",
                JSON.stringify({
                    toSkip: page * 10,
                    StudentID: StudentId,
                }),
                token
            );
            if (getClasses.Status == 1) {
                setTimeout(() => {
                    setCircleLoad(false);
                }, 200);
                setAllClasses(getClasses?.CompletedClasses);
                setpageCount(
                    Math.ceil(Number(getClasses?.ClassCount) / Number(10))
                );
            }
        } catch (err: any) {
            console.log(err);
            Error("Something went wrong try again later");
        }
    };

    useEffect(() => {
        getData(page);
    }, [page]);
    const handleChange = (pageNumber: any) => {
        setPage(pageNumber.selected);
    };
    const RateHandler = (e: React.MouseEvent<HTMLElement>) => {
        let ScheduleClassID = e.currentTarget.getAttribute("class-id");
        if (ScheduleClassID) {
            // eslint-disable-next-line
            let [data] = allClasses.filter(
                (item: any) => item.ScheduleClassID == ScheduleClassID
            );
            setDetails(data);
            setModal(true);
        }
    };
    return (
        <div className={TakenClasses["Container"]}>
            {modal && (
                <Rating
                    modal={modal}
                    setModal={setModal}
                    getDetails={details}
                    getHomePage={getData}
                />
            )}
            <header>
                <h3>Classes Taken</h3>
                <p>Summary of classes taken already</p>
                <div className={TakenClasses["table"]}>
                    {circleLoad ? (
                        <FallingLines
                            color="#4fa94d"
                            width="100"
                            visible={true}
                        />
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    <th>Index</th>
                                    <th>Date</th>
                                    <th>Course</th>
                                    <th>Feedback</th>
                                    <th>Session</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allClasses.length > 0 ? (
                                    allClasses.map((item: any, index: any) => (
                                        <tr>
                                            <td>
                                                {String(
                                                    index + 1 + page * 10
                                                ).padStart(2, "0")}
                                            </td>
                                            <td>
                                                <Moment format="DD, MMM hh:mm a">
                                                    {`${item.Scheduled_StartDate}T${item.Scheduled_StartTime}`}
                                                </Moment>{" "}
                                            </td>
                                            <td style={{ maxWidth: "250px" }}>
                                                <span>{item.CourseName}</span>
                                            </td>
                                            <td
                                                style={{
                                                    maxWidth: "200px",
                                                    fontWeight: "400",
                                                }}>
                                                {item.Comments}
                                            </td>
                                            <td style={{ maxWidth: "4rem" }}>
                                                {item.DownloadUrl != "NA" &&
                                                item.DownloadUrl.length > 0 ? (
                                                    <div
                                                        className={
                                                            TakenClasses[
                                                                "video-icon"
                                                            ]
                                                        }
                                                        onClick={() => {
                                                            const isPast90Days =
                                                                moment(
                                                                    `${item.Scheduled_StartDate} ${item.Scheduled_StartTime}`,
                                                                    "YYYY-MM-DD HH:mm:ss"
                                                                ).isBefore(
                                                                    moment().subtract(
                                                                        90,
                                                                        "days"
                                                                    )
                                                                );
                                                            if (isPast90Days) {
                                                                warningSwal(
                                                                    "Warning",
                                                                    "This recording is 90 days old,hence the link has expired,please reach out to the RM"
                                                                );
                                                            } else {
                                                                window.open(
                                                                    item.DownloadUrl,
                                                                    "_blank"
                                                                );
                                                            }
                                                        }}>
                                                        <IoVideocam size={22} />
                                                    </div>
                                                ) : (
                                                    <span>NA</span>
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className={
                                                        TakenClasses[
                                                            "link-upload"
                                                        ]
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={RateHandler}
                                                    class-id={
                                                        item.ScheduleClassID
                                                    }>
                                                    {item.Rating == 0
                                                        ? "Rate Class"
                                                        : "Edit Rating"}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No Class Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={page}
                                marginPagesDisplayed={2}
                                onPageChange={handleChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </header>
        </div>
    );
};

export default Taken;
