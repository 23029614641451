import { useState, useEffect, ChangeEvent } from "react";
import Layout from "../../../../Reusable/Tutor/Layout";
import DetailClasses from "./details.module.scss";
import { Services } from "../../../../Service/service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Error, Success } from "../../../../Reusable/utils/Toast";
import Moment from "react-moment";
import Select from "react-select";
import SkeletonLoad from "./SkeletonLoad/skeleton";
import Reschedule from "../Reschedule/reschedule";
import Report from "../Report/report";
import Complete from "../completeClass/completeClass";
import { FaEdit } from "react-icons/fa";
import Loader from "../../../../Reusable/Common/LoadingComponents/loader/loader";
import Swal from "sweetalert";

import CancelClass from "./popup";
import swal from "sweetalert";
import moment from "moment";
function Details() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [cancelLoad, setCancelLoad] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [rescheduleModal, setRescheduleModal] = useState<boolean>(false);
    const [cancel, setCancel] = useState(false);
    const [isChargable, setIsChargable] = useState<number>(1);
    const [data, setData] = useState<any>();
    const [attendanceLoad, setAttendanceLoad] = useState<boolean>(false);
    const [showReport, setShowreport] = useState<any>(null);
    const [completeClass, setCompleteClass] = useState<any>(null);
    const [erroMsg, setErrorMessage] = useState("");
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    // INITIAL RENDER
    useEffect(() => {
        Get_Details(params.get("id"));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "hidden") {
                // console.log("Tab is inactive");
                // Call a function when the tab is left
            } else if (document.visibilityState === "visible") {
                Get_Details(params.get("id"));
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, []);
    // // CANCEL CHARGABLE CONDITION VALIDATION
    // async function CancelValidate(body: string) {
    //     setLoading(true);
    //     try {
    //         const AllClasses = await Services.cancelClassValidate(
    //             "POST",
    //             body,
    //             token
    //         );
    //         if (AllClasses.Status === 1) {
    //             setIsChargable(AllClasses.IsHourBefore);
    //         }
    //     } catch (err: any) {
    //         console.log(err);
    //         setLoading(false);
    //         Error("something went wrong please try again");
    //     }
    // }

    // GET SCHEDULE CLASS DETAILS
    async function Get_Details(id: string | null) {
        setLoading(true);
        try {
            const AllClasses = await Services.Schedule_Classes_Details(
                "GET",
                null,
                token,
                id
            );
            if (AllClasses.Status === 1) {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                setData(AllClasses.ScheduleClasess);
            } else {
                Error(AllClasses.Message);
            }
        } catch (err: any) {
            console.log(err);
            if (attendanceLoad) {
                setAttendanceLoad(false);
            }
            setLoading(false);
            Error("something went wrong please try again");
        }
    }

    // COMPLETE CLASS FUNCTION
    async function Cancel_Class_API(validatebody: any, reason: string) {
        let LoginData: any = localStorage.getItem("LoginDetails");

        let Rms: any = [];
        if (LoginData) {
            LoginData = JSON.parse(LoginData);
            Rms = [
                LoginData.Lead_RM,
                LoginData.Buddy_RM,
                LoginData.SecondShift_RM_Morning,
                LoginData.SecondShift_RM_Evening,
            ];
        }
        let date = new Date(data.Scheduled_StartDate);
        let body = {
            ZoomMeetingID: data.ZoomMeetingID,
            Students: data?.Students,

            TutorRms: Rms.filter((item: any) => item != 0),
            ScheduleCreatedByType: data.ScheduleCreatedByType,
            ClassDuration: data?.ClassDuration,
            ScheduleCreatedBy: data.ScheduleCreatedBy,
            Scheduled_StartTime: data.Scheduled_StartTime,
            CancelReason: reason,
            Scheduled_StartDate: `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`,
            RequestType: 2,
            IsHourBefore: isChargable,
            BatchName: data.BatchName,
            TutorEmailID: data?.TutorEmailID,
        };
        console.log(body);
        // return;
        setCancelLoad(true);
        try {
            const AllClasses = await Services.cancelClassValidate(
                "POST",
                validatebody,
                token
            );
            if (AllClasses.Status === 1) {
                const CompleteClass = await Services.Cancel_Class(
                    "PUT",
                    JSON.stringify({
                        ...body,
                        IsHourBefore: AllClasses.IsHourBefore,
                    }),
                    token,
                    data?.ScheduleClassID
                );
                if (CompleteClass.Status === 1) {
                    swal("Completed", CompleteClass.Message, "success");
                    Get_Details(params.get("id"));
                    setCancel(false);
                } else {
                    Error(CompleteClass?.Message);
                    setCancelLoad(false);
                }
            }
        } catch (err: any) {
            console.log(err);
            setCancelLoad(false);
            Error("something went wrong please try again");
        }
    }
    // CANCEL CLASS
    function cancelHandler() {
        setCancel(true);
    }

    //Mark attendance
    async function attendanceHandler(event: ChangeEvent<HTMLSelectElement>) {
        const Scheduled_Classes_StudentID =
            event.currentTarget.getAttribute("attr-studentId");
        const Attendance = event.target.value;
        let body = JSON.stringify({
            Attendance,
            Scheduled_Classes_StudentID,
            BatchName: data.BatchName,
        });

        try {
            setAttendanceLoad(true);
            const Response = await Services.MarkAttendace("POST", body, token);
            if (Response.Status === 1) {
                setAttendanceLoad(false);
                Success(Response.Message);
                let details = data;

                let index = details.Students?.findIndex(
                    (item: any) =>
                        // eslint-disable-next-line
                        item.Scheduled_Classes_StudentID ==
                        Scheduled_Classes_StudentID
                );
                details.Students[index].Attendance = Number(Attendance);
                setData(details);
            } else {
                setAttendanceLoad(false);
                Error(Response?.Message);
            }
        } catch (err) {
            console.log(err);
            console.log(data);
            setAttendanceLoad(false);
            Error("Something went wrong please try again");
        }
    }

    //shows Report Modal for Eact Student required to complete class
    const reportHandler = (event: React.MouseEvent<HTMLElement>) => {
        const batchStudentId =
            event.currentTarget.getAttribute("custom-studentid");
        setShowreport(batchStudentId);
    };

    const BackButton = () => {
        Navigate(-1);
    };
    const startSessions = async () => {
        {
            const ScheduleClassID = params.get("id");
            setAttendanceLoad(true);
            let body = JSON.stringify({
                ScheduleClassID,
                TutorID: data.TutorID,
                BatchID: data.BatchID,
                BatchName: data.BatchName,
                Scheduled_StartTime: data?.Scheduled_StartTime,
                Scheduled_StartDate: data?.Scheduled_StartDate,
                Scheduled_EndTime: data?.Scheduled_EndTime,
            });

            try {
                setAttendanceLoad(true);
                const Response = await Services.getSessions(
                    "POST",
                    body,
                    token
                );
                if (Response.Status === 1) {
                    Get_Details(ScheduleClassID);

                    setAttendanceLoad(false);
                } else {
                    setAttendanceLoad(false);
                    Error(Response?.Message);
                }
            } catch (err) {
                setAttendanceLoad(false);
                Error("Something went wrong please try again");
            }
        }
    };
    const CancelApi = async (formData: any) => {
        setCancelLoad(true);
        try {
            const Response = await Services.bulk_cancel(
                "POST",
                JSON.stringify({
                    ...formData,
                    ScheduleClassIDs: [params.get("id")],
                    // ScheduleClassIDs: data.map(
                    //     (item: any) => item.ScheduleClassID
                    // ),

                    ClassesCount: 1,
                    FromDate: moment(data?.Scheduled_StartDate).format(
                        "YYYY-MM-DD"
                    ),
                    ToDate: moment(data?.Scheduled_StartDate).format(
                        "YYYY-MM-DD"
                    ),
                    Dates: [
                        moment(data?.Scheduled_StartDate).format("YYYY-MM-DD"),
                    ],
                    Cancelled_Dates: [
                        moment(data?.Scheduled_StartDate).format("YYYY-MM-DD"),
                    ],
                }),
                token
            );

            if (Response.Status === 1) {
                setCancel(false);
                Swal({
                    title: "Class Cancelled",

                    icon: "success",
                });
                setErrorMessage("");
                Get_Details(params.get("id"));
            } else {
                // Error(Response.Message);
                setErrorMessage(Response.Message);
            }
        } catch (err: any) {
            console.log(err);
            // setModal(false);
            Error("something went wrong please try again");
        } finally {
            setCancelLoad(false);
        }
    };
    function isValidDate(inputDate: string | Date): boolean {
        // Get the current date without the time component (midnight)
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Convert the inputDate to a Date object if it's in string format
        const givenDate = new Date(inputDate);

        // Compare the given date with the current date
        return givenDate >= currentDate; // Returns true if the date is today or in the future
    }
    return (
        <Layout Active={"Batch Request"}>
            {attendanceLoad && (
                <div className={DetailClasses["loader"]}>
                    <Loader />
                </div>
            )}
            {showReport && (
                <Report
                    modal={showReport}
                    setModal={setShowreport}
                    getDetails={{
                        details: Get_Details,
                        setLoading: setLoading,
                        data: data,
                    }}
                    id={params.get("id")}
                />
            )}
            {completeClass && (
                <Complete
                    modal={completeClass}
                    setModal={setCompleteClass}
                    getDetails={{
                        details: Get_Details,
                        setLoading: setLoading,
                        data: data,
                    }}
                    id={params.get("id")}
                />
            )}
            {cancel && (
                <CancelClass
                    modal={cancel}
                    setModal={setCancel}
                    getDetails={CancelApi}
                    cancelLoad={cancelLoad}
                    setcancelLoad={setCancelLoad}
                    erroMsg={erroMsg}
                    setErrorMessage={setErrorMessage}
                    timecheck={{
                        ScheduleClassIDs: [
                            {
                                Scheduled_StartDate: moment(
                                    data?.Scheduled_StartDate
                                ).format("YYYY-MM-DD"),
                                Scheduled_StartTime: data?.Scheduled_StartTime,
                                ScheduleClassID: params.get("id"),
                            },
                        ],
                    }}
                />
            )}
            {rescheduleModal && (
                <Reschedule
                    setRescheduleModal={setRescheduleModal}
                    rescheduleModal={rescheduleModal}
                    data={{
                        Scheduled_StartDate: data?.Scheduled_StartDate,
                        id: params.get("id"),
                        Students: data?.Students,
                        BatchName: data?.BatchName,
                        ScheduleClassID: data?.ScheduleClassID,
                        ZoomMeetingID: data?.ZoomMeetingID,
                        CourseName: data?.CourseName,
                        ScheduleCreatedBy: data.ScheduleCreatedBy,
                        details: Get_Details,
                        ScheduleCreatedByType: data?.ScheduleCreatedByType,
                        duration: data?.Duration,
                        ClassDuration: data?.ClassDuration,
                    }}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Class Details</title>
            </Helmet>
            <div className={DetailClasses["Container"]}>
                <button className={DetailClasses["back"]} onClick={BackButton}>
                    Back
                </button>
                <div className={DetailClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad />
                    ) : (
                        <div style={{ width: "100%", overflow: "auto" }}>
                            <div className={DetailClasses["header"]}>
                                <h3>
                                    <span></span>Class:{data?.ClassLessonName}
                                </h3>
                                <div className={DetailClasses["action-btns"]}>
                                    {data?.ClassStatus === 1 &&
                                        data?.CancelRequestStatus === 0 && (
                                            <button
                                                className={
                                                    DetailClasses[
                                                        "complete-class"
                                                    ]
                                                }
                                                onClick={() =>
                                                    setCompleteClass(true)
                                                }
                                                disabled={moment(
                                                    `${data?.Scheduled_StartDate} ${data?.Scheduled_StartTime}`,
                                                    "YYYY-MM-DD HH:mm:ss"
                                                ).isAfter(moment())}>
                                                Complete Class
                                            </button>
                                        )}
                                    {data?.ClassStatus === 1 &&
                                        data?.CancelRequestStatus === 0 && (
                                            <button
                                                className={
                                                    DetailClasses[
                                                        "complete-class"
                                                    ]
                                                }
                                                disabled={
                                                    !moment(
                                                        data?.Scheduled_StartDate,
                                                        "YYYY-MM-DD"
                                                    ).isSame(moment(), "day")
                                                }
                                                onClick={startSessions}>
                                                {data?.StartUrl === "NA"
                                                    ? "Start Session"
                                                    : "Restart Session"}
                                            </button>
                                        )}
                                    {data?.ClassStatus === 1 &&
                                        data?.ReScheduleStatus === 0 &&
                                        data?.CancelRequestStatus === 0 && (
                                            <button
                                                className={
                                                    DetailClasses["reschedule"]
                                                }
                                                onClick={() => {
                                                    setRescheduleModal(true);
                                                }}>
                                                Reschedule
                                            </button>
                                        )}
                                    {data?.ClassStatus === 1 &&
                                        data?.CancelRequestStatus === 0 &&
                                        isValidDate(
                                            data?.Scheduled_StartDate
                                        ) && (
                                            <button
                                                className={
                                                    DetailClasses[
                                                        "cancel-class"
                                                    ]
                                                }
                                                onClick={cancelHandler}>
                                                Cancel
                                            </button>
                                        )}
                                </div>
                                {data?.ClassStatus === 1 &&
                                    data?.ReScheduleStatus === 1 &&
                                    data?.CancelRequestStatus === 0 && (
                                        <p
                                            className={
                                                DetailClasses["rescheduled"]
                                            }>
                                            Rescheduled{" "}
                                        </p>
                                    )}
                                {data?.ClassStatus === 1 &&
                                    data?.ReScheduleStatus === 2 &&
                                    data?.CancelRequestStatus === 0 && (
                                        <p
                                            className={
                                                DetailClasses["requested"]
                                            }>
                                            Reschedule Requested
                                        </p>
                                    )}
                                {data?.ClassStatus === 1 &&
                                    data?.ReScheduleStatus === 3 &&
                                    data?.CancelRequestStatus === 0 && (
                                        <p
                                            className={
                                                DetailClasses["rejected"]
                                            }>
                                            Rescheduled Request rejected
                                        </p>
                                    )}

                                {data?.CancelRequestStatus === 1 && (
                                    <p className={DetailClasses["rejected"]}>
                                        Cancelled
                                    </p>
                                )}
                                {data?.CancelRequestStatus === 2 && (
                                    <p className={DetailClasses["rejected"]}>
                                        Cancel Requested
                                    </p>
                                )}
                                {data?.CancelRequestStatus === 3 && (
                                    <p className={DetailClasses["rejected"]}>
                                        Cancel rejected
                                    </p>
                                )}
                                {data?.ClassStatus === 2 && (
                                    <p
                                        className={
                                            DetailClasses["complete-class"]
                                        }>
                                        Completed
                                    </p>
                                )}
                            </div>
                            <div className={DetailClasses["profile"]}>
                                {/* <div className={DetailClasses["Contact"]}>
                                    <div>
                                        <div
                                            style={{
                                                border: "1px solid #ddd",
                                                padding: "0.2rem",
                                                width: "fit-content",
                                            }}>
                                            <header
                                                className={
                                                    DetailClasses["initials"]
                                                }
                                                style={{
                                                    backgroundColor: `darkblue`,
                                                }}>
                                                <h4>
                                                    {getInitials(
                                                        `${data?.TutorName}`
                                                    )}
                                                </h4>
                                            </header>
                                        </div>
                                    </div>
                                    <div className={DetailClasses["card-data"]}>
                                        <h4> Contact</h4>

                                        {data?.TutorEmailID?.length > 0 && (
                                            <p>
                                                {" "}
                                                <GrMail
                                                    color="#016997"
                                                    size={18}
                                                />
                                                <span>
                                                    {data?.TutorEmailID}
                                                </span>
                                            </p>
                                        )}
                                        {data?.TutorMobile && (
                                            <p>
                                                {" "}
                                                <FaPhone
                                                    color="#016997"
                                                    size={13}
                                                />
                                                <span>{data?.TutorMobile}</span>
                                            </p>
                                        )}
                                    </div>
                                </div> */}
                                <div className={DetailClasses["internal"]}>
                                    <h3>Meeting Details</h3>
                                    <div>
                                        <h3>Created By</h3>
                                        <p>{data?.ScheduleCreated}</p>
                                    </div>

                                    <div>
                                        <h3>Course</h3>
                                        <p>{data?.CourseName}</p>
                                    </div>
                                    {data?.ClassStatus === 1 &&
                                        data?.CancelRequestStatus != 1 && (
                                            <div>
                                                <h3>Meeting Start Url</h3>
                                                <p>
                                                    {data?.StartUrl &&
                                                    data?.StartUrl !== "NA" &&
                                                    data.StartUrl?.length >
                                                        0 ? (
                                                        <a
                                                            href={
                                                                data?.StartUrl
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer">
                                                            Link
                                                        </a>
                                                    ) : (
                                                        "NA"
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    {/* <div>
                                        <h3>Lesson Plan</h3>
                                        <p>
                                            {data?.ClassStatus === 1
                                                ? data?.PlanNumber_Batch
                                                : data?.ClassStatus === 2
                                                ? data?.PlanNumber_Class
                                                : "0"}
                                        </p>
                                    </div> */}
                                    <div>
                                        <h3>Duration</h3>
                                        <p>{data?.ClassDuration} Mins</p>
                                    </div>
                                    <div>
                                        <h3>Start Date:</h3>
                                        <p>
                                            <Moment format="DD-MM-YYYY">
                                                {
                                                    new Date(
                                                        data?.Scheduled_StartDate
                                                    )
                                                }
                                            </Moment>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Start Time:</h3>
                                        <p>{data?.Scheduled_StartTime}</p>
                                    </div>
                                    <div>
                                        <h3>End Time:</h3>
                                        <p>{data?.Scheduled_EndTime}</p>
                                    </div>
                                    {data.ClassStatus == 2 && (
                                        <div>
                                            <h3>Comments</h3>
                                            <p> {data.Comments}</p>
                                        </div>
                                    )}

                                    {/*   {data.ClassStatus == 2 && (
                                        <div>
                                            <h3>Topics Done</h3>
                                            <p> {data.NewTopicDone}</p>
                                        </div>
                                    )} */}
                                </div>
                            </div>

                            <div className={DetailClasses["Table"]}>
                                <h3>Student Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>

                                            <th>Attendance</th>
                                            <th>Report</th>
                                            <th>Review</th>
                                            <th>Rating</th>

                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.Students?.map((item: any) => {
                                            return (
                                                <tr>
                                                    <td>{item.StudentName}</td>

                                                    {data?.ClassStatus === 1 &&
                                                    data?.CancelRequestStatus !=
                                                        1 &&
                                                    data?.BatchSize ===
                                                        "Group" ? (
                                                        <td>
                                                            <select
                                                                className={
                                                                    DetailClasses[
                                                                        "select-dropdown"
                                                                    ]
                                                                }
                                                                defaultValue={
                                                                    item.Attendance
                                                                }
                                                                // disabled={
                                                                //     item.Attendance !==
                                                                //     0
                                                                // }
                                                                attr-studentId={
                                                                    item.Scheduled_Classes_StudentID
                                                                }
                                                                onChange={
                                                                    attendanceHandler
                                                                }
                                                                disabled={
                                                                    moment(
                                                                        data?.Scheduled_StartDate
                                                                    ).isAfter(
                                                                        moment(),
                                                                        "day"
                                                                    ) ||
                                                                    data.CancelRequestStatus ===
                                                                        1
                                                                }>
                                                                <option
                                                                    value={0}
                                                                    disabled>
                                                                    Mark
                                                                    Attendance
                                                                </option>
                                                                <option value="1">
                                                                    Present
                                                                </option>
                                                                <option value="2">
                                                                    Absent{" "}
                                                                </option>
                                                                {/* <option value="3">
                                                                    Absent but
                                                                    not charged
                                                                </option> */}
                                                            </select>
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            {item.Attendance ===
                                                                0 && "NA"}
                                                            {item.Attendance ===
                                                                1 && "Present"}
                                                            {item.Attendance ===
                                                                2 && "Absent"}
                                                            {item.Attendance ===
                                                                3 &&
                                                                "Absent but not chargable"}
                                                        </td>
                                                    )}

                                                    <td>
                                                        <button
                                                            className={`${
                                                                item.IsReportSubmitted ===
                                                                1
                                                                    ? DetailClasses[
                                                                          "report-edit"
                                                                      ]
                                                                    : DetailClasses[
                                                                          "report"
                                                                      ]
                                                            }`}
                                                            custom-studentid={
                                                                item.Scheduled_Classes_StudentID
                                                            }
                                                            disabled={
                                                                data.CancelRequestStatus ===
                                                                    1 ||
                                                                moment(
                                                                    data?.Scheduled_StartDate
                                                                ).isAfter(
                                                                    moment(),
                                                                    "day"
                                                                )
                                                            }
                                                            onClick={
                                                                reportHandler
                                                            }>
                                                            {item.IsReportSubmitted ===
                                                            1 ? (
                                                                <span>
                                                                    Reported
                                                                    <FaEdit />
                                                                </span>
                                                            ) : (
                                                                "Report"
                                                            )}
                                                        </button>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "250px",
                                                            wordWrap:
                                                                "break-word",
                                                        }}>
                                                        {item.Review}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "100px",
                                                        }}>
                                                        {item.Rating}
                                                    </td>

                                                    <td>
                                                        {item.Class_StudentStatus ===
                                                            3 ||
                                                        item.Class_StudentStatus ===
                                                            4 ? (
                                                            <span
                                                                className={
                                                                    DetailClasses[
                                                                        "cancelled"
                                                                    ]
                                                                }>
                                                                Not Attending
                                                                {/* Cancelled by{" "}
                                                                {item.CancelledBy ==
                                                                1
                                                                    ? "Administrator"
                                                                    : item.CancelledBy ==
                                                                      2
                                                                    ? "RM"
                                                                    : item.CancelledBy ==
                                                                      3
                                                                    ? "Tutor"
                                                                    : item.CancelledBy ==
                                                                      4
                                                                    ? "Parent"
                                                                    : "SalesManager"} */}
                                                            </span>
                                                        ) : (
                                                            "NA"
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Details;
