import React from "react";
import { useForm } from "react-hook-form";
import Styles from "./popup.module.scss";
import Modal from "react-modal";
import moment from "moment";
import ReactLoading from "react-loading";
import { ConfirmationDiaolog } from "Reusable/utils/utils";
import { Services } from "Service/service";

interface props {
    modal: any;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    getDetails?: any;
    dates: { ToDate: string; FromDate: string };
    erroMsg: string;
    cancelLoad: boolean;
    classes: any;
    selectedRows: string[];
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setcancelLoad: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Type {
    Comments: string;
    CancelReason: string;
}
const Popup: React.FC<props> = ({
    modal,
    setModal,
    getDetails,
    dates,
    setErrorMessage,
    erroMsg,
    cancelLoad,
    selectedRows,
    classes,
    setcancelLoad,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm<Type>();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const CancelReasons = [
        {
            value: "Bereavement",
            Label: "Bereavement",
        },
        { value: "One_Day", Label: "One Day Leave" },
        { value: "6_Class_Cancellation", Label: "6 Class Cancellation" },
        { value: "Medical_Emergency", Label: "Medical Emergency" },
        { value: "Long_Leave", Label: "Long Leave" },
        { value: "Other_Leaves", Label: "Others" },
    ];

    function areDatesConsecutive(dates: any, scheduledClasses: any) {
        // Convert strings to Date objects and sort them
        let sortedDates = dates
            .map((date: any) => new Date(date))
            .sort((a: any, b: any) => a - b);
        let scheduledSet = new Set(
            scheduledClasses.map(
                (date: any) => new Date(date).toISOString().split("T")[0]
            )
        );

        for (let i = 1; i < sortedDates.length; i++) {
            let prevDate = sortedDates[i - 1];
            let currDate = sortedDates[i];

            let diffDays = (currDate - prevDate) / (1000 * 60 * 60 * 24);

            if (diffDays > 1) {
                // Check if any missing date is in scheduled classes
                for (let j = 1; j < diffDays; j++) {
                    let missingDate = new Date(prevDate);
                    missingDate.setDate(prevDate.getDate() + j);

                    if (
                        scheduledSet.has(
                            missingDate.toISOString().split("T")[0]
                        )
                    ) {
                        return false; // Missing date is in scheduled classes -> Not consecutive
                    }
                }
            }
        }
        return true; // All dates are consecutive with respect to schedule
    }
    function areAllDatesSame(dates: any) {
        // Convert all dates to a common format (YYYY-MM-DD) and check if they are identical
        const firstDate = new Date(dates[0]).toISOString().split("T")[0];

        return dates.every(
            (date: any) =>
                new Date(date).toISOString().split("T")[0] === firstDate
        );
    }
    function areAllDatesSameMonth(dates: any) {
        // Extract the year and month of the first date
        const firstDate = new Date(dates[0]);
        const firstYear = firstDate.getFullYear();
        const firstMonth = firstDate.getMonth(); // getMonth() returns 0-indexed month (Jan = 0, Dec = 11)

        // Check if all other dates have the same year and month
        return dates.every((date: any) => {
            const d = new Date(date);
            return d.getFullYear() === firstYear && d.getMonth() === firstMonth;
        });
    }
    function getUniqueDates(dates: any) {
        let uniqueDates: any = {};
        let result: any = [];

        for (let date of dates) {
            let formattedDate = new Date(date).toISOString().split("T")[0];
            if (!uniqueDates[formattedDate]) {
                uniqueDates[formattedDate] = true;
                result.push(formattedDate);
            }
        }
        return result;
    }

    const Confirm = (data: any) => {
        getDetails({
            ...data,
        });
    };
    async function onSubmit(data: any) {
        setErrorMessage("");
        let fromDate = moment(dates.FromDate);
        let toDate = moment(dates.ToDate);
        let DateDifference = toDate.diff(fromDate, "days") + 1;
        let onlyDates = classes.filter((item: any) =>
            selectedRows.includes(item.ScheduleClassID)
        );
        let allDates = onlyDates.map((item: any) =>
            moment(item?.Scheduled_StartDate).format("YYYY-MM-DD")
        );
        let AllClassesDates = classes.map((item: any) =>
            moment(item?.Scheduled_StartDate).format("YYYY-MM-DD")
        );
        let isConsq = areDatesConsecutive(allDates, AllClassesDates);

        if (
            data.CancelReason == "One Day Leave" ||
            data.CancelReason == "6 Class Cancellation"
        ) {
            if (!areAllDatesSameMonth(allDates)) {
                return setError("CancelReason", {
                    message: "All Classes should be of same Month",
                });
            }
        }

        if (
            (data.CancelReason == "Bereavement" && !isConsq) ||
            (data.CancelReason == "Bereavement" &&
                getUniqueDates(allDates)?.length > 4)
        )
            return setError("CancelReason", {
                message:
                    "No more than 4 Consecutive days allowed in this Category",
            });
        if (data.CancelReason == "One Day Leave" && !areAllDatesSame(allDates))
            return setError("CancelReason", {
                message: "No more than 1 Day is Allowed",
            });
        if (
            (data.CancelReason == "Medical Emergency" && !isConsq) ||
            (data.CancelReason == "Medical Emergency" &&
                getUniqueDates(allDates)?.length > 7)
        )
            return setError("CancelReason", {
                message:
                    "No more than 7 Consecutive Days is Allowed in this Category",
            });
        // console.log(isConsq, getUniqueDates(allDates));
        if (
            (data.CancelReason == "Long Leave" && !isConsq) ||
            (data.CancelReason == "Long Leave" &&
                getUniqueDates(allDates).length > 5)
        )
            return setError("CancelReason", {
                message: "No more than 5 Days is Allowed in this Category",
            });
        let CancelType = CancelReasons.filter(
            (item) => item.Label === data.CancelReason
        );

        if (
            data.CancelReason === "Bereavement" ||
            data?.CancelReason == "Medical Emergency" ||
            data?.CancelReason == "Others"
        ) {
            let Message = `You will be charged a penalty for these classes, Please challenge it later`;
            ConfirmationDiaolog(Message, "Cancel Classes", Confirm, {
                ...data,
                CancelType: CancelType[0].value,
                NoOfDays: getUniqueDates(allDates).length,
                Cancelled_Dates: getUniqueDates(allDates),
            });
        } else if (data.CancelReason == "One Day Leave") {
            setcancelLoad(true);
            let FilteredData = classes.filter((item: any) =>
                selectedRows.includes(item.ScheduleClassID)
            );
            let body = {
                ScheduleClassIDs: FilteredData.map((item: any) => ({
                    Scheduled_StartTime: item?.Scheduled_StartTime,
                    Scheduled_StartDate: moment(
                        item?.Scheduled_StartDate
                    ).format("YYYY-MM-DD"),
                    ScheduleClassID: item?.ScheduleClassID,
                })),
            };
            try {
                const apiData = await Services.TimeCheck(
                    "POST",
                    JSON.stringify(body),
                    token
                );
                if (apiData.Status === 1) {
                    Confirm({
                        ...data,
                        CancelType: CancelType[0].value,

                        NoOfDays: getUniqueDates(allDates).length,
                        Cancelled_Dates: getUniqueDates(allDates),
                    });
                } else {
                    setErrorMessage(
                        "Some of your Classes are within 90 mins so you cannot choose One day leave as a reason"
                    );
                }
            } catch (error) {
                console.error("Error fetching Details:", error);
            } finally {
                setcancelLoad(false);
            }
        } else if (data.CancelReason == "6 Class Cancellation") {
            setcancelLoad(true);
            let FilteredData = classes.filter((item: any) =>
                selectedRows.includes(item.ScheduleClassID)
            );
            let body = {
                ScheduleClassIDs: FilteredData.map((item: any) => ({
                    Scheduled_StartTime: item?.Scheduled_StartTime,
                    Scheduled_StartDate: moment(
                        item?.Scheduled_StartDate
                    ).format("YYYY-MM-DD"),
                    ScheduleClassID: item?.ScheduleClassID,
                })),
            };
            try {
                const apiData = await Services.TimeCheck(
                    "POST",
                    JSON.stringify(body),
                    token
                );
                if (apiData.Status === 1) {
                    Confirm({
                        ...data,
                        CancelType: CancelType[0].value,

                        NoOfDays: getUniqueDates(allDates).length,
                        Cancelled_Dates: getUniqueDates(allDates),
                    });
                } else {
                    let Message = `Some of your Classes are within 90 mins,You will be charged a penalty for these classes, Please challenge it later.",
                    `;
                    ConfirmationDiaolog(Message, "Cancel Classes", Confirm, {
                        ...data,
                        CancelType: CancelType[0].value,

                        NoOfDays: getUniqueDates(allDates).length,
                        Cancelled_Dates: getUniqueDates(allDates),
                    });
                }
            } catch (error) {
                console.error("Error fetching Details:", error);
            } finally {
                setcancelLoad(false);
            }
        } else if (data.CancelReason == "Long Leave") {
            Confirm({
                ...data,
                CancelType: CancelType[0].value,

                NoOfDays: getUniqueDates(allDates).length,
                Cancelled_Dates: getUniqueDates(allDates),
            });
        }

        // reset({ Comments: "", CancelReason: "0" });
    }
    return (
        <>
            <Modal
                isOpen={modal}
                contentLabel="Modal"
                // onRequestClose={() => {
                //     setModal(false);
                //     reset({ Comments: "", CancelReason: "0" });
                // }}
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <header>
                        <h3>Bulk cancel</h3>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Cancellation Reason</label>
                            <select
                                defaultValue={"0"}
                                {...register("CancelReason", {
                                    required: "This is required",
                                    onChange: (e) => setErrorMessage(""),
                                })}>
                                <option value={"0"} disabled>
                                    Select Reason
                                </option>
                                {CancelReasons.map((item) => (
                                    <option value={item.Label}>
                                        {item.Label}
                                    </option>
                                ))}
                            </select>
                            {errors.CancelReason && (
                                <span style={{ color: "red" }}>
                                    {errors.CancelReason.message}
                                </span>
                            )}
                        </div>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Comments</label>
                            <textarea
                                {...register("Comments", {
                                    required: true,
                                })}></textarea>
                            {errors.Comments && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                        {erroMsg.length > 0 && (
                            <span style={{ color: "red", fontSize: "0.9rem" }}>
                                {erroMsg}
                            </span>
                        )}
                        {cancelLoad ? (
                            <div className={Styles["Control-btns"]}>
                                <ReactLoading
                                    color="#0d3075"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            </div>
                        ) : (
                            <div className={Styles["Control-btns"]}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setModal(false);
                                        setErrorMessage("");
                                        reset({
                                            Comments: "",
                                            CancelReason: "0",
                                        });
                                    }}>
                                    Close
                                </button>
                                <button type="submit">Submit</button>
                            </div>
                        )}
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Popup;
