import { FaRegIdCard, FaMoneyBillAlt } from "react-icons/fa";
import { BsCalendar3 } from "react-icons/bs";
import { IoIosBook } from "react-icons/io";
import { TbHelp } from "react-icons/tb";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdFreeCancellation } from "react-icons/md";

import {
    MdOutlineEventAvailable,
    MdOutlineCancelPresentation,
    MdDashboard,
} from "react-icons/md";
export const menuItems = [
    {
        id: 1,
        multiple: false,
        name: "Dashboard",
        menuLink: "/Tutor/dashboard",
        active: "Dashboard",
        icon: MdDashboard,
    },
    {
        id: 2,
        multiple: false,
        name: "Calender",
        menuLink: "/Tutor/Calender",
        active: "Calender",
        icon: BsCalendar3,
    },

    {
        id: 3,
        multiple: false,
        name: "Batches",
        menuLink: "/Tutor/Batch-request",
        active: "Batch Request",
        icon: FaRegIdCard,
    },
    {
        id: 4,
        multiple: false,
        name: "Cancellation Report",
        menuLink: "/Tutor/cancellation-report",
        active: "Cancellation Report",
        icon: MdFreeCancellation,
    },
    {
        id: 5,
        multiple: false,
        name: "Demo Classes",
        menuLink: "/Tutor/demo-classes",
        active: "Demo Classes",
        icon: FaChalkboardTeacher,
    },
    {
        id: 6,
        multiple: false,
        name: "Bulk Cancel Class",
        menuLink: "/Tutor/Bulk-cancel",
        active: "Bulk Cancel",
        icon: MdOutlineCancelPresentation,
    },
    /* {
        id: 7,
        multiple: false,
        name: "Courses",
        menuLink: "/Tutor/Courses",
        active: "Courses",
        icon: IoIosBook,
    }, */
    {
        id: 8,
        multiple: false,
        name: "Earnings",
        menuLink: "/Tutor/Earnings?type=1",
        active: "Earnings",
        icon: FaMoneyBillAlt,
    },

    // {
    //     id: 7,
    //     multiple: false,
    //     name: "Leave",
    //     menuLink: "/Tutor/Leave",
    //     active: "Leave",
    //     icon: LuLuggage,
    // },
    {
        id: 9,
        multiple: false,
        name: "Tutor Availability",
        menuLink: "/Tutor/Availability",
        active: "Tutor Availability",
        icon: MdOutlineEventAvailable,
    },

    // {
    //     id: 9,
    //     multiple: false,
    //     name: "Notifications",
    //     menuLink: "/Tutor/Notifications",
    //     active: "Notifications",
    //     icon: IoIosNotifications,
    // },
];
