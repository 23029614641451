import React, { useEffect, useState } from "react";
import Layout from "Reusable/Tutor/Layout";
import { Helmet } from "react-helmet";
import CancelClass from "./cancel.module.scss";
import { useForm } from "react-hook-form";
import Classes from "../classes/Classes";
import { Services } from "../../../Service/service";
import Loader from "Reusable/Common/LoadingComponents/loader/loader";
import { useSearchParams } from "react-router-dom";
import Popup from "./cancelPopup/popup";
import Swal from "sweetalert";
import { Error } from "Reusable/utils/Toast";
import GetCancelRequest from "./allrequests/GetCancelRequest";
interface Date {
    FromDate: string;
    ToDate: string;
}

function Cancel() {
    const [searchparams, setsearchparams] = useSearchParams();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [cancelLoad, setcancelLoad] = useState(false);
    const [Active, setActive] = useState<string>("new");
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [dates, SetDates] = useState<Date>({ FromDate: "", ToDate: "" });
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm<Date>();
    const watchData = watch();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    useEffect(() => {
        const FromDate = searchparams.get("FromDate");
        const ToDate = searchparams.get("ToDate");
        if (FromDate && ToDate) {
            setValue("FromDate", FromDate);
            setValue("ToDate", ToDate);
            let body = {
                FromDate: FromDate,
                ToDate: ToDate,
            };
            fetchClasses(body);
        }
    }, []);
    const fetchClasses = async (data: any) => {
        setLoading(true);
        SetDates(data);
        try {
            const Response = await Services.getScheduled_class_by_date(
                "POST",
                JSON.stringify(data),
                token
            );

            if (Response.Status === 1) {
                setData(Response.ScheduleClasess);
                if (
                    Response?.ScheduleClasess &&
                    Response.ScheduleClasess?.length > 0
                ) {
                    setSelectedRows(
                        Response?.ScheduleClasess?.map(
                            (item: any) => item.ScheduleClassID
                        )
                    );
                }
                setErrorMessage("");
            } else {
                Error(Response.Message);
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (data: Date) => {
        setsearchparams({ ...data });

        fetchClasses(data);
    };

    function getDatesBetween(fromDate: any, toDate: any) {
        const datesArray = [];
        const currentDate = new Date(fromDate);
        const endDate = new Date(toDate);

        while (currentDate <= endDate) {
            datesArray.push(currentDate.toISOString().split("T")[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return datesArray;
    }
    const cancel = async (formData: any) => {
        setcancelLoad(true);
        console.log(selectedRows);
        try {
            const Response = await Services.bulk_cancel(
                "POST",
                JSON.stringify({
                    ...formData,
                    ScheduleClassIDs: selectedRows,
                    // ScheduleClassIDs: data.map(
                    //     (item: any) => item.ScheduleClassID
                    // ),

                    ClassesCount: selectedRows.length,
                    FromDate: watchData.FromDate,
                    ToDate: watchData.ToDate,
                    Dates: getDatesBetween(
                        watchData.FromDate,
                        watchData.ToDate
                    ),
                }),
                token
            );

            if (Response.Status === 1) {
                setModal(false);
                setsearchparams({});
                setData([]);
                reset({ FromDate: "", ToDate: "" });
                Swal({
                    title: "Class Cancelled",

                    icon: "success",
                });
                setErrorMessage("");
            } else {
                // Error(Response.Message);
                setErrorMessage(Response.Message);
            }
        } catch (err: any) {
            console.log(err);
            // setModal(false);
            Error("something went wrong please try again");
        } finally {
            setcancelLoad(false);
        }
    };
    const NavHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        let Type = String(event.currentTarget.getAttribute("custom-btn-type"));
        setActive(Type);
    };
    const handleCheckboxChange = (id: string) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const handleSelectAll = () => {
        const allIds = data.map((item: any) => item.ScheduleClassID);

        if (selectedRows.length === allIds.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(allIds);
        }
    };
    return (
        <Layout Active={"Bulk Cancel"}>
            {modal && (
                <Popup
                    modal={modal}
                    setModal={setModal}
                    getDetails={cancel}
                    classes={data}
                    cancelLoad={cancelLoad}
                    dates={dates}
                    erroMsg={errorMessage}
                    selectedRows={selectedRows}
                    setErrorMessage={setErrorMessage}
                    setcancelLoad={setcancelLoad}
                />
            )}
            {cancelLoad && <Loader />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cancel Class</title>
            </Helmet>
            <div className={CancelClass["Container"]}>
                <div className={CancelClass["wrapper"]}>
                    <h3>Bulk Cancel Classes</h3>
                    <div className={CancelClass["Navigator"]}>
                        <button
                            custom-btn-type="new"
                            className={
                                Active === "new" ? CancelClass["active"] : ""
                            }
                            onClick={NavHandler}>
                            New Request
                        </button>
                        <button
                            custom-btn-type="existing"
                            className={
                                Active === "existing"
                                    ? CancelClass["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Existing Requests
                        </button>
                    </div>
                    {Active === "new" ? (
                        <>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className={CancelClass["date-filter"]}>
                                <div className={CancelClass["form-control"]}>
                                    <label htmlFor="">From Date</label>
                                    <input
                                        type="date"
                                        min={
                                            new Date()
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                        {...register("FromDate", {
                                            required: "this field is required",
                                            onChange: (e) => {
                                                setData([]);
                                            },
                                        })}
                                    />
                                </div>
                                <div className={CancelClass["form-control"]}>
                                    <label htmlFor="">To Date</label>
                                    <input
                                        type="date"
                                        min={
                                            new Date()
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                        {...register("ToDate", {
                                            required: "this field is required",
                                            onChange: (e) => {
                                                setData([]);
                                            },
                                        })}
                                    />
                                    {/* {errors.FromDate && (
                                    <span>{errors.FromDate.message}</span>
                                )} */}
                                </div>
                                <button>Search</button>
                            </form>
                            <Classes
                                Loading={loading}
                                classesData={data}
                                handleCheckboxChange={handleCheckboxChange}
                                handleSelectAll={handleSelectAll}
                                rowSelect={true}
                                selectedRows={selectedRows}
                            />

                            <button
                                className={CancelClass["cancel"]}
                                onClick={() => {
                                    setModal(true);
                                }}
                                disabled={data.length === 0}>
                                {" "}
                                Cancel Classes
                            </button>
                        </>
                    ) : (
                        <GetCancelRequest />
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Cancel;
