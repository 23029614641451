import React from "react";
import { useForm } from "react-hook-form";
import Styles from "./popup.module.scss";
import Modal from "react-modal";
import moment from "moment";
import ReactLoading from "react-loading";
import { ConfirmationDiaolog } from "Reusable/utils/utils";
import { Services } from "Service/service";

interface props {
    modal: any;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    getDetails?: any;
    erroMsg: string;
    cancelLoad: boolean;
    timecheck: any;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setcancelLoad: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Type {
    Comments: string;
    CancelReason: string;
}
const Popup: React.FC<props> = ({
    modal,
    setModal,
    getDetails,
    setErrorMessage,
    erroMsg,
    cancelLoad,
    setcancelLoad,
    timecheck,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm<Type>();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const CancelReasons = [
        {
            value: "Bereavement",
            Label: "Bereavement",
        },
        { value: "One_Day", Label: "One Day" },
        { value: "6_Class_Cancellation", Label: "6 Class Cancellation" },
        { value: "Medical_Emergency", Label: "Medical Emergency" },
        { value: "Long_Leave", Label: "Long Leave" },
        { value: "Other_Leaves", Label: "Others" },
    ];
    const Confirm = (data: any) => {
        getDetails({
            ...data,

            IsSingleCancel: 1,
        });
    };
    async function onSubmit(data: any) {
        setErrorMessage("");

        if (data.CancelReason == "One Day" || data.CancelReason == "Long Leave")
            return setError("CancelReason", {
                message:
                    "Please cancel via Bulk Class Cancellation for long leave and one day options.",
            });

        let CancelType = CancelReasons.filter(
            (item) => item.Label === data.CancelReason
        );

        if (
            data.CancelReason === "Bereavement" ||
            data?.CancelReason == "Medical Emergency" ||
            data?.CancelReason == "Others"
        ) {
            let Message = `You will be charged a penalty for these classes, Please challenge it later`;
            ConfirmationDiaolog(Message, "Cancel Classes", Confirm, {
                ...data,
                NoOfDays: 1,
                CancelType: CancelType[0].value,
            });
        } else if (data.CancelReason == "6 Class Cancellation") {
            setcancelLoad(true);

            let body = {
                ...timecheck,
            };
            try {
                const apiData = await Services.TimeCheck(
                    "POST",
                    JSON.stringify(body),
                    token
                );
                if (apiData.Status === 1) {
                    Confirm({
                        ...data,
                        NoOfDays: 1,
                        CancelType: CancelType[0].value,
                    });
                } else {
                    let Message = `Some of your Classes are within 90 mins,You will be charged a penalty for these classes, Please challenge it later.",
                    `;
                    ConfirmationDiaolog(Message, "Cancel Classes", Confirm, {
                        ...data,
                        NoOfDays: 1,
                        CancelType: CancelType[0].value,
                    });
                }
            } catch (error) {
                console.error("Error fetching Details:", error);
            } finally {
                setcancelLoad(false);
            }
        } else if (data.CancelReason == "Long Leave") {
            Confirm({
                ...data,
                CancelType: CancelType[0].value,
            });
        }

        // reset({ Comments: "", CancelReason: "0" });
    }
    return (
        <>
            <Modal
                isOpen={modal}
                contentLabel="Modal"
                // onRequestClose={() => {
                //     setModal(false);
                //     reset({ Comments: "", CancelReason: "0" });
                // }}
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <header>
                        <h3> cancel Class</h3>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Cancellation Reason</label>
                            <select
                                defaultValue={"0"}
                                {...register("CancelReason", {
                                    required: "This is required",
                                    onChange: (e) => setErrorMessage(""),
                                })}>
                                <option value={"0"} disabled>
                                    Select Reason
                                </option>
                                {CancelReasons.map((item) => (
                                    <option value={item.Label}>
                                        {item.Label}
                                    </option>
                                ))}
                            </select>
                            {errors.CancelReason && (
                                <span style={{ color: "red" }}>
                                    {errors.CancelReason.message}
                                </span>
                            )}
                        </div>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Comments</label>
                            <textarea
                                {...register("Comments", {
                                    required: true,
                                })}></textarea>
                            {errors.Comments && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                        {erroMsg.length > 0 && (
                            <span style={{ color: "red", fontSize: "0.9rem" }}>
                                {erroMsg}
                            </span>
                        )}
                        {cancelLoad ? (
                            <div className={Styles["Control-btns"]}>
                                <ReactLoading
                                    color="#0d3075"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            </div>
                        ) : (
                            <div className={Styles["Control-btns"]}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setModal(false);
                                        setErrorMessage("");
                                        reset({
                                            Comments: "",
                                            CancelReason: "0",
                                        });
                                    }}>
                                    Close
                                </button>
                                <button type="submit">Submit</button>
                            </div>
                        )}
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Popup;
