import React, { useEffect, useState } from "react";
import Layout from "Reusable/Tutor/Layout";
import DashboardClass from "./dashboard.module.css";
import TutorClasses from "./request.module.scss";
import ReactPaginate from "react-paginate";

import { FaRupeeSign } from "react-icons/fa";
import Loader from "Reusable/Common/LoadingComponents/loader/loader";
import { Services } from "Service/service";
import { useForm, SubmitHandler } from "react-hook-form";
import { IoInformationCircleSharp } from "react-icons/io5";
import Info from "./Modal/Modal";
import { warningSwal } from "Reusable/utils/Toast";
import Moment from "react-moment";
import moment from "moment";
type Inputs = {
    StartDate: string;
    EndDate: string;
};
function Dashboard() {
    const [upcomingClasses, setUpcomingClasses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPaginate, setShowPagiate] = useState(true);
    const [pageCount, setpageCount] = useState(1);
    const [TutorData, setTutorData] = useState<any>({ leaveStatus: 2 });

    let RecordCount = 20;

    const [data, setData] = useState([]);
    const [Active, setActive] = useState<string>("TUTOR");

    const [Parent, setParent] = useState({
        TotalDurationMinutes: 0,
        NoOfClassesCancelled: 0,
        ImpactedMinutes: 0,
        ImpactedHours: 0,
    });
    const [Teachers, setTeachers] = useState({
        TotalDurationMinutes: 0,
        NoOfClassesCancelled: 0,
        ImpactedMinutes: 0,
        ImpactedHours: 0,
    });
    const [Others, setOthers] = useState({
        TotalDurationMinutes: 0,
        NoOfClassesCancelled: 0,
        ImpactedMinutes: 0,
        ImpactedHours: 0,
    });
    const [forcepage, setforcepage] = useState(0);

    const [modal, setModal] = useState(false);
    let userdata: any = localStorage.getItem("LoginDetails");
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {},
    } = useForm<Inputs>();
    const WatchData = watch();
    const formData = watch();
    if (userdata) {
        userdata = JSON.parse(userdata);
    }
    useEffect(() => {
        ApiCall(null);
    }, []);

    const ApiCall = async (data: any) => {
        setLoading(true);
        try {
            let body = JSON.stringify({ ...data });
            const [summaryData] = await Promise.all([
                Services.tutor_cancel_reports("POST", body, token),
            ]);

            if (summaryData.Status === 1) {
                setTutorData(summaryData?.TutorData[0]);

                getBatchRequests(Active, 0);
                setforcepage(0);
                let Parent = summaryData?.CancelReports.find(
                    (item: any) => item?.CancelType == "Parent"
                );
                if (Parent) {
                    setParent(Parent);
                } else {
                    setParent({
                        TotalDurationMinutes: 0,
                        NoOfClassesCancelled: 0,
                        ImpactedMinutes: 0,
                        ImpactedHours: 0,
                    });
                }
                let Other = summaryData?.CancelReports.find(
                    (item: any) => item?.CancelType == "Other"
                );
                if (Other) {
                    setOthers(Other);
                } else {
                    setOthers({
                        TotalDurationMinutes: 0,
                        NoOfClassesCancelled: 0,
                        ImpactedMinutes: 0,
                        ImpactedHours: 0,
                    });
                }
                let Tutor = summaryData?.CancelReports.find(
                    (item: any) => item?.CancelType == "Tutor"
                );
                if (Tutor) {
                    setTeachers(Tutor);
                } else {
                    setTeachers({
                        TotalDurationMinutes: 0,
                        NoOfClassesCancelled: 0,
                        ImpactedMinutes: 0,
                        ImpactedHours: 0,
                    });
                }
                /*  if (summaryData.Earnings != null) {
                    setEarning({
                        Earnings:
                            summaryData.Earnings != null
                                ? summaryData.Earnings
                                : 0,
                        NeedToPay:
                            summaryData.NeedToPay != null
                                ? summaryData.NeedToPay
                                : 0,
                    });
                } */
            }
        } catch {
            setLoading(false);
            alert("something went wrong try again");
        }
    };

    useEffect(() => {
        getBatchRequests(Active, 0);
    }, [Active]);

    // ---GET BATCH REQUESTS

    async function getBatchRequests(Type: string, skip: any) {
        try {
            const response = await Services.cancelledclasses_by_type(
                "POST",
                JSON.stringify({
                    CancelledByType: Type,
                    toSkip: skip,
                    EndDate: WatchData.EndDate,
                    StartDate: WatchData?.StartDate,
                }),
                token
            );

            setTimeout(() => {
                setLoading(false);
            }, 200);
            if (response?.Status == 1) {
                setData(response.CancelReports);
                if (
                    response.CancelReports.length == 0 ||
                    response.ClassesCount <= RecordCount
                ) {
                    setShowPagiate(false);
                } else {
                    if (!showPaginate) {
                        setShowPagiate(true);
                    }
                    setpageCount(
                        Math.ceil(
                            Number(response?.ClassesCount) / Number(RecordCount)
                        )
                    );
                }
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if (moment(data.StartDate).isAfter(data.EndDate)) {
            warningSwal("Warning", "Invalid Date range");
            return;
        }
        ApiCall(data);
    };
    const NavHandler = (type: string) => {
        setActive(type);
        setforcepage(0);
    };
    const handlePageChange = (selectedObject: any) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();

        window.scrollTo({ top: 10, behavior: "smooth" });
        setforcepage(selectedObject.selected);
        getBatchRequests(Active, selectedObject.selected * RecordCount);
        setLoading(true);
    };
    return (
        <Layout Active={"Cancellation Report"}>
            {loading && <Loader />}
            {modal && <Info modal={modal} setModal={setModal} />}
            <div
                className={DashboardClass["Container"]}
                style={{ minHeight: "100%" }}>
                <div className={DashboardClass["wrapper"]}>
                    <header>
                        <div>
                            <h3>
                                Cancellation Report{" "}
                                <button onClick={() => setModal(true)}>
                                    <IoInformationCircleSharp />
                                </button>
                            </h3>
                            {/*  <p>
                                Welcome to Dashboard here you find overview of
                                you work
                            </p> */}
                        </div>
                        <form
                            className={DashboardClass["filter"]}
                            onSubmit={handleSubmit(onSubmit)}>
                            {/*  <select
                                {...register("Earning_SummaryType", {
                                    required: true,
                                    onChange: (e) => changeHandler(e),
                                })}>
                                <option value="ThisWeek">This Week</option>
                                <option value="ThisMonth">This Month</option>
                                <option value="ByDate">By Date</option>
                            </select> */}
                            <div>
                                <input
                                    type="text"
                                    placeholder="From Date"
                                    {...register("StartDate", {
                                        required: true,
                                    })}
                                    onClick={(event) => {
                                        const inputElement =
                                            event.target as HTMLInputElement;
                                        inputElement.type = "date";
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="To Date"
                                    {...register("EndDate", {
                                        required: true,
                                    })}
                                    onClick={(event) => {
                                        const inputElement =
                                            event.target as HTMLInputElement;
                                        inputElement.type = "date";
                                    }}
                                />
                                <button>Submit</button>
                            </div>
                        </form>
                    </header>
                    <div className={DashboardClass["earnings"]}>
                        <div>
                            <p>No. of classes cancelled by the teacher </p>
                            <h3>{Teachers?.NoOfClassesCancelled}</h3>
                            <p>(impacted hours)</p>
                            <h3 style={{ fontSize: "1.2rem" }}>
                                {Teachers?.ImpactedHours != 0 &&
                                    `${Teachers.ImpactedHours} Hour`}{" "}
                                {Teachers?.ImpactedMinutes != 0 &&
                                    `${Teachers.ImpactedMinutes} Minutes`}
                                {Teachers?.ImpactedMinutes == 0 &&
                                    Teachers?.ImpactedHours == 0 &&
                                    `0 Minutes`}
                            </h3>
                        </div>
                        <div>
                            <p>No. of classes cancelled by the parent</p>
                            <h3>{Parent?.NoOfClassesCancelled}</h3>
                            <p>(impacted hours)</p>
                            <h3 style={{ fontSize: "1.2rem" }}>
                                {Parent?.ImpactedHours != 0 &&
                                    `${Parent.ImpactedHours} Hour`}{" "}
                                {Parent?.ImpactedMinutes != 0 &&
                                    `${Parent.ImpactedMinutes} Minutes`}
                                {Parent?.ImpactedMinutes == 0 &&
                                    Parent?.ImpactedHours == 0 &&
                                    `0 Minutes`}
                            </h3>
                        </div>
                        <div>
                            <p>No. of classes cancelled by the RM</p>
                            <h3>{Others?.NoOfClassesCancelled}</h3>
                            <p>(impacted hours)</p>
                            <h3 style={{ color: "blue", fontSize: "1.2rem" }}>
                                {Others?.ImpactedHours != 0 &&
                                    `${Others.ImpactedHours} Hour`}{" "}
                                {Others?.ImpactedMinutes != 0 &&
                                    `${Others.ImpactedMinutes} Minutes`}
                                {Others?.ImpactedMinutes == 0 &&
                                    Others?.ImpactedHours == 0 &&
                                    `0 Minutes`}
                            </h3>
                        </div>
                        <div>
                            <p>
                                Long Leave Taken{" "}
                                <span
                                    style={{
                                        color: "white",
                                        backgroundColor:
                                            TutorData?.LeaveStatus == 1
                                                ? "red"
                                                : "green",
                                        padding: "0.4rem",
                                        borderRadius: "10px",
                                    }}>
                                    {TutorData?.LeaveStatus == 1
                                        ? "Applied"
                                        : TutorData?.LeaveStatus == 0
                                        ? "Available"
                                        : "Not Applicable"}{" "}
                                </span>
                            </p>
                            <h3>{TutorData?.NoOfDays}</h3>
                            <p>(impacted days)</p>
                            <h3
                                style={{
                                    color: "black",
                                    fontSize: "1.2rem",
                                }}>
                                {TutorData.LeaveStatus == 1
                                    ? `${moment(TutorData?.FromDate).format(
                                          "Do MMMM YYYY"
                                      )} - ${moment(TutorData?.ToDate).format(
                                          "Do MMMM YYYY"
                                      )}`
                                    : "Not Applicable"}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["Tutor"]}>
                    <h3>Classes</h3>
                    <div className={TutorClasses["Navigator"]}>
                        <button
                            custom-btn-type="TUTOR"
                            className={
                                Active === "TUTOR" ? TutorClasses["active"] : ""
                            }
                            onClick={() => NavHandler("TUTOR")}>
                            TUTOR
                        </button>
                        <button
                            custom-btn-type="PARENT"
                            className={
                                Active === "PARENT"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={() => NavHandler("PARENT")}>
                            Parent
                        </button>
                        <button
                            custom-btn-type="OTHERS"
                            className={
                                Active === "OTHERS"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={() => NavHandler("OTHERS")}>
                            RM
                        </button>
                    </div>
                    <div className={TutorClasses["Table"]}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Class</th>

                                    <th>Start Date Time</th>
                                    {/* <th>End Time</th> */}
                                    <th>Duration</th>
                                    <th>Batch</th>
                                    <th>Cancel Reason</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? (
                                    data.map((item: any, index: any) => {
                                        const timeStr = item?.DemoStartTime;

                                        // Combine date and time
                                        const dateTime = moment(
                                            item?.DemoStartDate
                                        ).set({
                                            hour: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).hours(),
                                            minute: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).minutes(),
                                            second: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).seconds(),
                                        });
                                        return (
                                            <tr>
                                                <td>{item.ClassLessonName}</td>

                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {
                                                            new Date(
                                                                item.Scheduled_StartDate
                                                            )
                                                        }
                                                    </Moment>{" "}
                                                    <br />
                                                    {String(
                                                        item.Scheduled_StartTime
                                                    )}
                                                </td>
                                                {/*  <td>
                                                    {String(
                                                        item.Scheduled_EndTime
                                                    )}
                                                </td> */}
                                                <td>
                                                    {" "}
                                                    {item.ClassDuration} Min
                                                </td>
                                                <td>
                                                    {item.BatchName}
                                                    {/*  <br /> */}
                                                    {/*    <span
                                                        style={{
                                                            fontSize: "0.9rem",
                                                        }}>
                                                        {" "}
                                                        ({item.BatchSize})
                                                    </span> */}
                                                </td>

                                                <td
                                                    style={{
                                                        wordBreak: "break-word",
                                                        maxWidth: "3rem",
                                                    }}>
                                                    {item.CancelReason}
                                                </td>
                                                <td>{item.Comments}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={6}> no records Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Dashboard;
